export const GET_CHAT_USERS = "GET_CHAT_USERS";
export const ADD_CHAT_USER = "ADD_CHAT_USER";
export const UPDATE_CHAT_USER = "UPDATE_CHAT_USER";
export const CHANGE_ACTIVE_CHAT = "CHANGE_ACTIVE_CHAT";

export const FETCH_CHATS_REQUEST = "FETCH_CHATS_REQUEST";
export const FETCH_CHATS_SUCCESS = "FETCH_CHATS_SUCCESS";

export const ADD_CHAT_MESSAGE = "ADD_CHAT_MESSAGE";
export const UPDATE_CHAT_MESSAGE = "UPDATE_CHAT_MESSAGE";
export const REMOVE_CHAT_MESSAGE = "REMOVE_CHAT_MESSAGE";
export const UPDATE_CHAT_PRODUCTS = "UPDATE_CHAT_PRODUCTS";
export const LAST_ACTIVE_TIMESTAMP = "LAST_ACTIVE_TIMESTAMP";
export const GET_GROUP_DATA = "GET_GROUP_DATA";

export const FETCH_USERS_DATA_REQUEST = "FETCH_USERS_DATA_REQUEST";
export const FETCH_USERS_DATA_SUCCESS = "FETCH_USERS_DATA_SUCCESS";

export const FETCH_USER_REALTIME_DATA_SUCCESS =
  "FETCH_USER_REALTIME_DATA_SUCCESS";

export const FETCH_ACCOUNT_REALTIME_DATA_SUCCESS =
  "FETCH_ACCOUNT_REALTIME_DATA_SUCCESS";

export const LOGOUT = "LOGOUT";

export const FETCH_INVENTORIES_DATA_REQUEST = "FETCH_INVENTORIES_DATA_REQUEST";
export const FETCH_INVENTORIES_DATA_SUCCESS = "FETCH_INVENTORIES_DATA_SUCCESS";

export const CHECKBOX_INVENTORY_FOR_RETURN = "CHECKBOX_INVENTORY_FOR_RETURN";

export const FETCH_ORDERS_DATA_REQUEST = "FETCH_ORDERS_DATA_REQUEST";
export const FETCH_ORDERS_DATA_SUCCESS = "FETCH_ORDERS_DATA_SUCCESS";

export const RESET_ORDERS_DATA = "RESET_ORDERS_DATA";

export const FILTER_ORDERS_TYPE = "FILTER_ORDERS_TYPE";

export const FETCH_ORDER_DATA_REQUEST = "FETCH_ORDER_DATA_REQUEST";
export const FETCH_ORDER_DATA_SUCCESS = "FETCH_ORDER_DATA_SUCCESS";

export const RESET_ORDER_DATA = "RESET_ORDER_DATA";

export const FETCH_SHIPMENTS_DATA_REQUEST = "FETCH_SHIPMENTS_DATA_REQUEST";
export const FETCH_SHIPMENTS_DATA_SUCCESS = "FETCH_SHIPMENTS_DATA_SUCCESS";

export const FILTER_SHIPMENTS_TYPE = "FILTER_SHIPMENTS_TYPE";

export const FETCH_SHIPMENT_DATA_REQUEST = "FETCH_SHIPMENT_DATA_REQUEST";
export const FETCH_SHIPMENT_DATA_SUCCESS = "FETCH_SHIPMENT_DATA_SUCCESS";

export const FETCH_PICKUP_DATA_REQUEST = "FETCH_PICKUP_DATA_REQUEST";
export const FETCH_PICKUP_DATA_SUCCESS = "FETCH_PICKUP_DATA_SUCCESS";

export const FETCH_PICKUPS_DATA_SUCCESS = "FETCH_PICKUPS_DATA_SUCCESS";
export const FETCH_PICKUPS_DATA_REQUEST = "FETCH_PICKUPS_DATA_REQUEST";

export const FILTER_PICKUPS_TYPE = "FILTER_PICKUPS_TYPE";

export const ADD_PICKUPS_FOR_INVOICE = "ADD_PICKUPS_FOR_INVOICE";

export const FETCH_PICKUP_INVOICES_DATA_SUCCESS =
  "FETCH_PICKUP_INVOICES_DATA_SUCCESS";

export const FETCH_INVENTORY_ADDITIONS_DATA_REQUEST =
  "FETCH_INVENTORY_ADDITIONS_DATA_REQUEST";
export const FETCH_INVENTORY_ADDITIONS_DATA_SUCCESS =
  "FETCH_INVENTORY_ADDITIONS_DATA_SUCCESS";

export const FETCH_IAR_FOR_PICKUP_DATA_REQUEST =
  "FETCH_IAR_FOR_PICKUP_DATA_REQUEST";
export const FETCH_IAR_FOR_PICKUP_DATA_SUCCESS =
  "FETCH_IAR_FOR_PICKUP_DATA_SUCCESS";

export const CHECKBOX_FOR_IAR_PICKUP = "CHECKBOX_FOR_IAR_PICKUP";

export const FETCH_INVENTORY_ADDITION_DATA_REQUEST =
  "FETCH_INVENTORY_ADDITION_DATA_REQUEST";
export const FETCH_INVENTORY_ADDITION_DATA_SUCCESS =
  "FETCH_INVENTORY_ADDITION_DATA_SUCCESS";

export const FETCH_USER_DATA_REQUEST = "FETCH_USER_DATA_REQUEST";
export const FETCH_USER_DATA_SUCCESS = "FETCH_USER_DATA_SUCCESS";

export const CREATE_SELLER_REQUEST = "CREATE_SELLER_REQUEST";
export const CREATE_SELLER_SUCCESS = "CREATE_SELLER_SUCCESS";

export const FETCH_USER_ORDERS_DATA_REQUEST = "FETCH_USER_ORDERS_DATA_REQUEST";
export const FETCH_USER_ORDERS_DATA_SUCCESS = "FETCH_USER_ORDERS_DATA_SUCCESS";

export const UPDATE_USER_DATA_REQUEST = "UPDATE_USER_DATA_REQUEST";
export const UPDATE_USER_DATA_SUCCESS = "UPDATE_USER_DATA_SUCCESS";
export const UPDATE_USER_DATA_FAILURE = "UPDATE_USER_DATA_FAILURE";

export const FETCH_CANCELLATIONS_DATA_REQUEST =
  "FETCH_CANCELLATIONS_DATA_REQUEST";
export const FETCH_CANCELLATIONS_DATA_SUCCESS =
  "FETCH_CANCELLATIONS_DATA_SUCCESS";

export const FETCH_EXCHANGES_DATA_REQUEST = "FETCH_EXCHANGES_DATA_REQUEST";
export const FETCH_EXCHANGES_DATA_SUCCESS = "FETCH_EXCHANGES_DATA_SUCCESS";

export const FETCH_EXCHANGES_FOR_PICKUPS_REQUEST =
  "FETCH_EXCHANGES_FOR_PICKUPS_REQUEST";
export const FETCH_EXCHANGES_FOR_PICKUPS_SUCCESS =
  "FETCH_EXCHANGES_FOR_PICKUPS_SUCCESS";

export const ASSIGN_FOR_PICKUP_CHECKED = "ASSIGN_FOR_PICKUP_CHECKED";

export const FETCH_EXCHANGES_FOR_SHIPMENTS_REQUEST =
  "FETCH_EXCHANGES_FOR_SHIPMENTS_REQUEST";
export const FETCH_EXCHANGES_FOR_SHIPMENTS_SUCCESS =
  "FETCH_EXCHANGES_FOR_SHIPMENTS_SUCCESS";

export const ASSIGN_FOR_SHIPMENT_CHECKED = "ASSIGN_FOR_SHIPMENT_CHECKED";

export const FETCH_BF_DATA_REQUEST = "FETCH_BF_DATA_REQUEST";
export const FETCH_BF_DATA_SUCCESS = "FETCH_BF_DATA_SUCCESS";

export const FETCH_BFS_DATA_REQUEST = "FETCH_BFS_DATA_REQUEST";
export const FETCH_BFS_DATA_SUCCESS = "FETCH_BFS_DATA_SUCCESS";

export const FETCH_BFS_FOR_SHIPMENT_DATA_REQUEST =
  "FETCH_BFS_FOR_SHIPMENT_DATA_REQUEST";
export const FETCH_BFS_FOR_SHIPMENT_DATA_SUCCESS =
  "FETCH_BFS_FOR_SHIPMENT_DATA_SUCCESS";

export const CHECKBOX_FOR_BFS_SHIPMENT = "CHECKBOX_FOR_BFS_SHIPMENT";

export const FETCH_EXCHANGE_DATA_REQUEST = "FETCH_EXCHANGE_DATA_REQUEST";
export const FETCH_EXCHANGE_DATA_SUCCESS = "FETCH_EXCHANGE_DATA_SUCCESS";

export const FETCH_PRODUCT_DATA_REQUEST = "FETCH_PRODUCT_DATA_REQUEST";
export const FETCH_PRODUCT_DATA_SUCCESS = "FETCH_PRODUCT_DATA_SUCCESS";

export const RESET_PRODUCT_DATA = "RESET_PRODUCT_DATA";

export const FETCH_PRODUCT_CATEGORY_DATA_SUCCESS =
  "FETCH_PRODUCT_CATEGORY_DATA_SUCCESS";

export const FETCH_PRODUCT_SUGGESTIONS_REQUEST =
  "FETCH_PRODUCT_SUGGESTIONS_REQUEST";
export const FETCH_PRODUCT_SUGGESTIONS_SUCCESS =
  "FETCH_PRODUCT_SUGGESTIONS_SUCCESS";

export const SELECT_SHIPMENT_FOR_INVOICE = "SELECT_SHIPMENT_FOR_INVOICE";

export const FETCH_SHIPMENT_INVOICES_DATA_SUCCESS =
  "FETCH_SHIPMENT_INVOICES_DATA_SUCCESS";
export const ADD_SHIPMENTS_FOR_INVOICE = "ADD_SHIPMENTS_FOR_INVOICE";

export const CHANGE_ACCEPT_QUANTITY_IAR_CONFIRM =
  "CHANGE_ACCEPT_QUANTITY_IAR_CONFIRM";
export const PICKUP_PRODUCTS_TOGGLE_CHECKED = "PICKUP_PRODUCTS_TOGGLE_CHECKED";
export const FETCH_PROCESS_PICKUP_DATA_REQUEST =
  "FETCH_PROCESS_PICKUP_DATA_REQUEST";
export const FETCH_PROCESS_PICKUP_DATA_SUCCESS =
  "FETCH_PROCESS_PICKUP_DATA_SUCCESS";
export const CHANGE_FIELDS_PROCESS_PICKUP = "CHANGE_FIELDS_PROCESS_PICKUP";
export const UPDATE_SFS_FIELDS_PROCESS_PICKUP =
  "UPDATE_SFS_FIELDS_PROCESS_PICKUP";
export const PUSH_TO_FINAL_REQUEST_FOR_PROCESS_PICKUP =
  "PUSH_TO_FINAL_REQUEST_FOR_PROCESS_PICKUP";
export const CHANGE_ASSIGN_SELLER_PROCESS_PICKUP =
  "CHANGE_ASSIGN_SELLER_PROCESS_PICKUP";

export const FETCH_CART_DATA_REQUEST = "FETCH_CART_DATA_REQUEST";
export const FETCH_CART_DATA_SUCCESS = "FETCH_CART_DATA_SUCCESS";

export const FETCH_CARTS_DATA_REQUEST = "FETCH_CARTS_DATA_REQUEST";
export const FETCH_CARTS_DATA_SUCCESS = "FETCH_CARTS_DATA_SUCCESS";

export const FETCH_WISHLISTS_DATA_REQUEST = "FETCH_WISHLISTS_DATA_REQUEST";
export const FETCH_WISHLISTS_DATA_SUCCESS = "FETCH_WISHLISTS_DATA_SUCCESS";

export const FETCH_CHECKOUT_DATA_REQUEST = "FETCH_CHECKOUT_DATA_REQUEST";
export const FETCH_CHECKOUT_DATA_SUCCESS = "FETCH_CHECKOUT_DATA_SUCCESS";

export const FETCH_BUYER_ORDERS_DATA_REQUEST =
  "FETCH_BUYER_ORDERS_DATA_REQUEST";
export const FETCH_BUYER_ORDERS_DATA_SUCCESS =
  "FETCH_BUYER_ORDERS_DATA_SUCCESS";

export const FETCH_CONFIRM_ORDER = "FETCH_CONFIRM_ORDER";
export const FETCH_CONFIRM_ORDER_DATA_SUCCESS =
  "FETCH_CONFIRM_ORDER_DATA_SUCCESS";

export const REQUEST_CANCEL_PRODUCT = "REQUEST_CANCEL_PRODUCT";
export const FETCH_CANCEL_PRODUCT_SUCCESS = "FETCH_CANCEL_PRODUCT_SUCCESS";

export const FETCH_USER_ADDRESSES = "FETCH_USER_ADDRESSES";
export const FETCH_USER_ADDRESSES_SUCCESS = "FETCH_USER_ADDRESSES_SUCCESS";

export const UPDATE_USER_ADDRESSES_REQUEST = "UPDATE_USER_ADDRESSES_REQUEST";
export const UPDATE_USER_ADDRESSES_DATA = "UPDATE_USER_ADDRESSES_DATA";

export const DELETE_USER_ADDRESSES_REQUEST = "DELETE_USER_ADDRESSES_REQUEST";
export const DELETE_USER_ADDRESSES_DATA = "DELETE_USER_ADDRESSES_DATA";

export const POST_USER_ADDRESS = "POST_USER_ADDRESS";
export const DELETE_USER_ADDRESS = "DELELTE_USER_ADDRESS";

export const FETCH_PRODUCTS_REQUEST = "FETCH_PRODUCTS_REQUEST";
export const FETCH_PRODUCTS_SUCCESS = "FETCH_PRODUCTS_SUCCESS";

export const RESET_PRODUCTS = "RESET_PRODUCTS";

export const FETCH_SELLER_PRODUCTS = "FETCH_SELLER_PRODUCTS";
export const FETCH_SELLER_PRODUCTS_SUCCESS = "FETCH_SELLER_PRODUCTS_SUCCESS";

export const RESET_SELLER_PRODUCTS = "RESET_SELLER_PRODUCTS";

export const UPDATE_PRODUCT_REQUEST = "UPDATE_PRODUCT_REQUEST";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";

export const UPDATE_PRODUCT_VARIATIONS_REQUEST =
  "UPDATE_PRODUCT_VARIATIONS_REQUEST";
export const UPDATE_PRODUCT_VARIATIONS_SUCCESS =
  "UPDATE_PRODUCT_VARIATIONS_SUCCESS";

export const FETCH_PRODUCT_RULES_DATA_REQUEST =
  "FETCH_PRODUCT_RULES_DATA_REQUEST";
export const FETCH_PRODUCT_RULES_DATA_SUCCESS =
  "FETCH_PRODUCT_RULES_DATA_SUCCESS";

export const CREATE_PRODUCT_RULE_REQUEST = "CREATE_PRODUCT_RULE_REQUEST";

export const FETCH_PRODUCT_RULE_DATA_REQUEST =
  "FETCH_PRODUCT_RULE_DATA_REQUEST";
export const FETCH_PRODUCT_RULE_DATA_SUCCESS =
  "FETCH_PRODUCT_RULE_DATA_SUCCESS";

export const CREATE_PRODUCT_RULE_DATA = "CREATE_PRODUCT_RULE_DATA";

export const FETCH_SUBCATEGORY_FILTERS_REQUEST =
  "FETCH_SUBCATEGORY_FILTERS_REQUEST";
export const FETCH_SUBCATEGORY_FILTERS_SUCCESS =
  "FETCH_SUBCATEGORY_FILTERS_SUCCESS";

export const UPDATE_SUBCATEGORY_FILTERS = "UPDATE_SUBCATEGORY_FILTERS";

export const UPDATE_SELLER_PRODUCT_REQUEST = "UPDATE_SELLER_PRODUCT_REQUEST";
export const UPDATE_SELLER_PRODUCT_SUCCESS = "UPDATE_SELLER_PRODUCT_SUCCESS";

export const UPDATE_BULK_PRODUCT_REQUEST = "UPDATE_BULK_PRODUCT_REQUEST";
export const UPDATE_BULK_PRODUCT_SUCCESS = "UPDATE_BULK_PRODUCT_SUCCESS";

export const FETCH_KEYWORDS_REQUEST = "FETCH_KEYWORDS_REQUEST";
export const FETCH_KEYWORDS_SUCCESS = "FETCH_KEYWORDS_SUCCESS";

export const ADD_KEYWORD_REQUEST = "ADD_KEYWORD_REQUEST";
export const ADD_KEYWORD_SUCCESS = "ADD_KEYWORD_SUCCESS";

export const UPDATE_KEYWORD_SUCCESS = "UPDATE_KEYWORD_SUCCESS";

export const REMOVE_KEYWORD_SUCCESS = "REMOVE_KEYWORD_SUCCESS";

export const FETCH_KEYWORD_GROUPS_REQUEST = "FETCH_KEYWORD_GROUPS_REQUEST";
export const FETCH_KEYWORD_GROUPS_SUCCESS = "FETCH_KEYWORD_GROUPS_SUCCESS";

export const FETCH_KEYWORD_PRODUCT_DATA_REQUEST =
  "FETCH_KEYWORD_PRODUCT_DATA_REQUEST";
export const FETCH_KEYWORD_PRODUCT_DATA_SUCCESS =
  "FETCH_KEYWORD_PRODUCT_DATA_SUCCESS";

export const FETCH_AGGREGATIONS_REQUEST = "FETCH_AGGREGATIONS_REQUEST";
export const FETCH_AGGREGATIONS_SUCCESS = "FETCH_AGGREGATIONS_SUCCESS";

export const FETCH_UPLOADS_DATA_REQUEST = "FETCH_UPLOADS_DATA_REQUEST";
export const FETCH_UPLOADS_DATA_SUCCESS = "FETCH_UPLOADS_DATA_SUCCESS";

export const FETCH_UPLOAD_PRODUCTS_DATA_REQUEST =
  "FETCH_UPLOAD_PRODUCTS_DATA_REQUEST";
export const FETCH_UPLOAD_PRODUCTS_DATA_SUCCESS =
  "FETCH_UPLOAD_PRODUCTS_DATA_SUCCESS";

export const FETCH_UPLOADED_ID_SUCCESS = "FETCH_UPLOADED_ID_SUCCESS";

export const BULK_UPDATE_PRODUCTS_PROGRESS = "BULK_UPDATE_PRODUCTS_PROGRESS";
export const GET_ACTIVE_CALLS = "GET_ACTIVE_CALLS";

export const FETCH_LEDGER_USERS_DATA_REQUEST =
  "FETCH_LEDGER_USERS_DATA_REQUEST";
export const FETCH_LEDGER_USERS_DATA_SUCCESS =
  "FETCH_LEDGER_USERS_DATA_SUCCESS";
export const FETCH_LEDGER_DATA_REQUEST = "FETCH_LEDGER_DATA_REQUEST";
export const FETCH_LEDGER_DATA_SUCCESS = "FETCH_LEDGER_DATA_SUCCESS";
export const RESET_LEDGER_DATA = "RESET_LEDGER_DATA";

export const CREATE_PAYOUT_REQUEST = "CREATE_PAYOUT_REQUEST";
export const FETCH_PAYOUT_REQUEST_DATA_SUCCESS =
  "FETCH_PAYOUT_REQUEST_DATA_SUCCESS";

export const ADD_PRODUCT_TO_CART_REQUEST = "ADD_PRODUCT_TO_CART_REQUEST";
export const ADD_PRODUCT_TO_CART_DATA = "ADD_PRODUCT_TO_CART_DATA";

export const FETCH_LAYOUTS_REQUEST = "FETCH_LAYOUTS_REQUEST";
export const FETCH_LAYOUTS_SUCCESS = "FETCH_LAYOUTS_SUCCESS";

export const FETCH_LAYOUT_REQUEST = "FETCH_LAYOUT_REQUEST";
export const FETCH_LAYOUT_SUCCESS = "FETCH_LAYOUT_SUCCESS";

export const FETCH_LAYOUT_HISTORY_REQUEST = "FETCH_LAYOUT_HISTORY_REQUEST";
export const FETCH_LAYOUT_HISTORY_SUCCESS = "FETCH_LAYOUT_HISTORY_SUCCESS";

export const MARK_LAYOUT_DEFAULT_REQUEST = "MARK_LAYOUT_DEFAULT_REQUEST";
export const MARK_LAYOUT_DEFAULT_SUCCESS = "MARK_LAYOUT_DEFAULT_SUCCESS";

export const DELETE_LAYOUT_REQUEST = "DELETE_LAYOUT_REQUEST";
export const DELETE_LAYOUT_SUCCESS = "DELETE_LAYOUT_SUCCESS";

export const FETCH_LAYOUT_RULES_DATA_REQUEST =
  "FETCH_LAYOUT_RULES_DATA_REQUEST";
export const FETCH_LAYOUT_RULES_DATA_SUCCESS =
  "FETCH_LAYOUT_RULES_DATA_SUCCESS";

export const CREATE_LAYOUT_RULE_REQUEST = "CREATE_LAYOUT_RULE_REQUEST";
export const CREATE_LAYOUT_RULE_SUCCESS = "CREATE_LAYOUT_RULE_SUCCESS";

export const FETCH_LAYOUT_RULE_DATA_REQUEST = "FETCH_LAYOUT_RULE_DATA_REQUEST";
export const FETCH_LAYOUT_RULE_DATA_SUCCESS = "FETCH_LAYOUT_RULE_DATA_SUCCESS";

export const FETCH_COMPONENTS_REQUEST = "FETCH_COMPONENTS_REQUEST";
export const FETCH_COMPONENTS_SUCCESS = "FETCH_COMPONENTS_SUCCESS";

export const FETCH_COMPONENT_REQUEST = "FETCH_COMPONENT_REQUEST";
export const FETCH_COMPONENT_SUCCESS = "FETCH_COMPONENT_SUCCESS";

export const FETCH_COMPONENT_HISTORY_REQUEST =
  "FETCH_COMPONENT_HISTORY_REQUEST";
export const FETCH_COMPONENT_HISTORY_SUCCESS =
  "FETCH_COMPONENT_HISTORY_SUCCESS";

export const MARK_COMPONENT_DEFAULT_REQUEST = "MARK_COMPONENT_DEFAULT_REQUEST";
export const MARK_COMPONENT_DEFAULT_SUCCESS = "MARK_COMPONENT_DEFAULT_SUCCESS";

export const DELETE_COMPONENT_REQUEST = "DELETE_COMPONENT_REQUEST";
export const DELETE_COMPONENT_SUCCESS = "DELETE_COMPONENT_SUCCESS";

export const FETCH_SELLER_STOREFRONT_REQUEST =
  "FETCH_SELLER_STOREFRONT_REQUEST";
export const FETCH_SELLER_STOREFRONT_SUCCESS =
  "FETCH_SELLER_STOREFRONT_SUCCESS";

export const FETCH_BUYER_LAYOUT_REQUEST = "FETCH_BUYER_LAYOUT_REQUEST";
export const FETCH_BUYER_LAYOUT_SUCCESS = "FETCH_BUYER_LAYOUT_SUCCESS";

export const FETCH_ACCOUNTS_REQUEST = "FETCH_ACCOUNTS_REQUEST";
export const FETCH_ACCOUNTS_SUCCESS = "FETCH_ACCOUNTS_SUCCESS";
export const FETCH_ACCOUNTS_FAILURE = "FETCH_ACCOUNTS_FAILURE";

export const FETCH_ACCOUNT_REQUEST = "FETCH_ACCOUNT_REQUEST";
export const FETCH_ACCOUNT_SUCCESS = "FETCH_ACCOUNT_SUCCESS";
export const FETCH_ACCOUNT_FAILURE = "FETCH_ACCOUNT_FAILURE";

export const CREATE_ACCOUNT_REQUEST = "CREATE_ACCOUNT_REQUEST";
export const CREATE_ACCOUNT_SUCCESS = "CREATE_ACCOUNT_SUCCESS";

export const UPDATE_ACCOUNT_REQUEST = "UPDATE_ACCOUNT_REQUEST";
export const UPDATE_ACCOUNT_SUCCESS = "UPDATE_ACCOUNT_SUCCESS";

export const REFRESH_USAGE_DATA_REQUEST = "REFRESH_USAGE_DATA_REQUEST";
export const REFRESH_USAGE_DATA_SUCCESS = "REFRESH_USAGE_DATA_SUCCESS";
export const REFRESH_USAGE_DATA_FAILURE = "REFRESH_USAGE_DATA_FAILURE";

export const EXTEND_SUBSCRIPTION_REQUEST = "EXTEND_SUBSCRIPTION_REQUEST";
export const EXTEND_SUBSCRIPTION_SUCCESS = "EXTEND_SUBSCRIPTION_SUCCESS";

export const BLOCK_UNBLOCK_REQUEST = "BLOCK_UNBLOCK_REQUEST";
export const BLOCK_UNBLOCK_SUCCESS = "BLOCK_UNBLOCK_SUCCESS";
export const BLOCK_UNBLOCK_FAILURE = "BLOCK_UNBLOCK_FAILURE";

export const PLAYAPP_UPLOADS_BLOCK_UNBLOCK_REQUEST =
  "PLAYAPP_UPLOADS_BLOCK_UNBLOCK_REQUEST";
export const PLAYAPP_UPLOADS_BLOCK_UNBLOCK_SUCCESS =
  "PLAYAPP_UPLOADS_BLOCK_UNBLOCK_SUCCESS";
export const PLAYAPP_UPLOADS_BLOCK_UNBLOCK_FAILURE =
  "PLAYAPP_UPLOADS_BLOCK_UNBLOCK_FAILURE";

export const CHANGE_SUBSCRIPTION_REQUEST = "CHANGE_SUBSCRIPTION_REQUEST";
export const CHANGE_SUBSCRIPTION_SUCCESS = "CHANGE_SUBSCRIPTION_SUCCESS";
export const CHANGE_SUBSCRIPTION_FAILURE = "CHANGE_SUBSCRIPTION_FAILURE";

export const UPDATE_SUBSCRIPTION_REQUEST = "UPDATE_SUBSCRIPTION_REQUEST";
export const UPDATE_SUBSCRIPTION_SUCCESS = "UPDATE_SUBSCRIPTION_SUCCESS";

export const FETCH_CREATE_USER_REQUEST = "FETCH_CREATE_USER_REQUEST";
export const FETCH_CREATE_USER_SUCCESS = "FETCH_CREATE_USER_SUCCESS";
export const FETCH_CREATE_USER_FAILURE = "FETCH_CREATE_USER_FAILURE";

export const FETCH_LOCATION_CLUSTERS_REQUEST =
  "FETCH_LOCATION_CLUSTERS_REQUEST";
export const FETCH_LOCATION_CLUSTERS_DATA_SUCCESS =
  "FETCH_LOCATION_CLUSTERS_DATA_SUCCESS";

export const CREATE_OR_UPDATE_LOCATION_CLUSTERS_REQUEST =
  "CREATE_OR_UPDATE_LOCATION_CLUSTERS_REQUEST";

export const FETCH_PRICE_CLUSTERS_REQUEST = "FETCH_PRICE_CLUSTERS_REQUEST";
export const FETCH_PRICE_CLUSTERS_SUCCESS = "FETCH_PRICE_CLUSTERS_SUCCESS";

export const CREATE_OR_UPDATE_PRICE_CLUSTERS_REQUEST =
  "CREATE_OR_UPDATE_PRICE_CLUSTERS_REQUEST";

export const FETCH_CASHBACK_CLUSTERS_REQUEST =
  "FETCH_CASHBACK_CLUSTERS_REQUEST";
export const FETCH_CASHBACK_CLUSTERS_SUCCESS =
  "RECIEVE_CASHBACK_CLUSTERS_SUCCESS";

export const CREATE_OR_UPDATE_CASHBACK_CLUSTERS_REQUEST =
  "CREATE_OR_UPDATE_CASHBACK_CLUSTERS_REQUEST";

export const FETCH_CUSTOMER_GROUP_REQUEST = "FETCH_CUSTOMER_GROUP_REQUEST";
export const FETCH_CUSTOMER_GROUP_SUCCESS = "FETCH_CUSTOMER_GROUP_SUCCESS";

export const CREATE_OR_UPDATE_CUSTOMER_GROUP_REQUEST =
  "CREATE_OR_UPDATE_CUSTOMER_GROUP_REQUEST";

export const CREATE_CAMPAIGN_REQUEST = "CREATE_CAMPAIGN_REQUEST";

export const FETCH_ACCOUNT_CAMPAIGNS_REQUEST =
  "FETCH_ACCOUNT_CAMPAIGNS_REQUEST";
export const FETCH_ACCOUNT_CAMPAIGNS_SUCCESS =
  "FETCH_ACCOUNT_CAMPAIGNS_SUCCESS";

export const FETCH_ACCOUNT_CAMPAIGN_DATA_REQUEST =
  "FETCH_ACCOUNT_CAMPAIGN_DATA_REQUEST";
export const FETCH_ACCOUNT_CAMPAIGN_DATA_SUCCESS =
  "FETCH_ACCOUNT_CAMPAIGN_DATA_SUCCESS";

export const UPDATE_CAMPAIGN_REQUEST = "UPDATE_CAMPAIGN_REQUEST";

export const FETCH_TRIPS_REQUEST = "FETCH_TRIPS_REQUEST";
export const FETCH_TRIPS_SUCCESS = "FETCH_TRIPS_SUCCESS";
export const FETCH_TRIPS_FAILURE = "FETCH_TRIPS_FAILURE";

export const UPDATE_TRIP_LOCATION_DATA = "UPDATE_TRIP_LOCATION_DATA";
export const UPDATE_TRIP_BATTERY_DATA = "UPDATE_TRIP_BATTERY_DATA";

export const FETCH_TRIP_REQUEST = "FETCH_TRIP_REQUEST";
export const FETCH_TRIP_SUCCESS = "FETCH_TRIP_SUCCESS";
export const FETCH_TRIP_FAILURE = "FETCH_TRIP_FAILURE";

export const FETCH_ONGOING_TRIPS = "FETCH_ONGOING_TRIPS";
export const FETCH_ONGOING_TRIPS_SUCCESS = "FETCH_ONGOING_TRIPS_SUCCESS";
export const FETCH_ONGOING_TRIPS_FAILURE = "FETCH_ONGOING_TRIPS_FAILURE";

export const FETCH_LOCATIONS_REQUEST = "FETCH_LOCATIONS_REQUEST";
export const FETCH_LOCATIONS_DATA_SUCCESS = "FETCH_LOCATIONS_DATA_SUCCESS";

export const FETCH_REFERRALS_DATA_REQUEST = "FETCH_REFERRALS_DATA_REQUEST";
export const FETCH_REFERRALS_DATA_SUCCESS = "FETCH_REFERRALS_DATA_SUCCESS";

export const FETCH_REFERRALS_USER_DATA_REQUEST =
  "FETCH_REFERRALS_USER_DATA_REQUEST";
export const FETCH_REFERRALS_USER_DATA_SUCCESS =
  "FETCH_REFERRALS_USER_DATA_SUCCESS";

export const UPDATE_SELLER_INVENTORY_REQUEST =
  "UPDATE_SELLER_INVENTORY_REQUEST";
export const UPDATE_SELLER_INVENTORY_DATA = "UPDATE_SELLER_INVENTORY_DATA";

export const UPDATE_INVENTORY_REQUEST = "UPDATE_INVENTORY_REQUEST";
export const UPDATE_INVENTORY_DATA = "UPDATE_INVENTORY_DATA";

export const FETCH_INVENTORY_RULES_DATA_REQUEST =
  "FETCH_INVENTORY_RULES_DATA_REQUEST";
export const FETCH_INVENTORY_RULES_DATA_SUCCESS =
  "FETCH_INVENTORY_RULES_DATA_SUCCESS";

export const CREATE_INVENTORY_RULE_REQUEST = "CREATE_INVENTORY_RULE_REQUEST";

export const FETCH_INVENTORY_RULE_DATA_REQUEST =
  "FETCH_INVENTORY_RULE_DATA_REQUEST";
export const FETCH_INVENTORY_RULE_DATA_SUCCESS =
  "FETCH_INVENTORY_RULE_DATA_SUCCESS";

export const CREATE_INVENTORY_RULE_DATA = "CREATE_INVENTORY_RULE_DATA";

export const UPDATE_USER_SETTINGS_REQUEST = "UPDATE_USER_SETTINGS_REQUEST";
export const UPDATE_USER_SETTINGS_SUCCESS = "UPDATE_USER_SETTINGS_SUCCESS";

export const FETCH_ANDROID_APP_DATA_REQUEST = "FETCH_ANDROID_APP_DATA_REQUEST";
export const FETCH_ANDROID_APP_DATA_SUCCESS = "FETCH_ANDROID_APP_DATA_SUCCESS";

export const CREATE_ANDROID_APP_REQUEST = "CREATE_ANDROID_APP_REQUEST";
export const CREATE_ANDROID_APP_DATA = "CREATE_ANDROID_APP_DATA";

export const UPLOAD_ANDROID_APP_REQUEST = "UPLOAD_ANDROID_APP_REQUEST";
export const UPLOAD_ANDROID_APP_SUCCESS = "UPLOAD_ANDROID_APP_SUCCESS";
export const UPLOAD_ANDROID_APP_FAILURE = "UPLOAD_ANDROID_APP_FAILURE";

export const PROMOTE_ANDROID_APP_REQUEST = "UPLOAD_ANDROID_APP_REQUEST";
export const PROMOTE_ANDROID_APP_SUCCESS = "UPLOAD_ANDROID_APP_SUCCESS";
export const PROMOTE_ANDROID_APP_FAILURE = "UPLOAD_ANDROID_APP_FAILURE";

export const REFRESH_PAGE = "REFRESH_PAGE";

export const FETCH_RELEASES_REQUEST = "FETCH_RELEASES_REQUEST";
export const FETCH_RELEASES_SUCCESS = "FETCH_RELEASES_SUCCESS";

export const CREATE_RELEASE_REQUEST = "CREATE_RELEASE_REQUEST";
export const CREATE_RELEASE_SUCCESS = "CREATE_RELEASE_SUCCESS";
export const CREATE_RELEASE_FAILURE = "CREATE_RELEASE_FAILURE";

export const UPDATE_RELEASE_REQUEST = "UPDATE_RELEASE_REQUEST";
export const UPDATE_RELEASE_SUCCESS = "UPDATE_RELEASE_SUCCESS";
export const UPDATE_RELEASE_FAILURE = "UPDATE_RELEASE_FAILURE";

export const CREATE_OUTBOUND_CALL_REQUEST = "CREATE_OUTBOUND_CALL";
export const CREATE_OUTBOUND_CALL_SUCCESS = "CREATE_OUTBOUND_CALL_SUCCESS";
export const CREATE_OUTBOUND_CALL_FAILURE = "CREATE_OUTBOUND_CALL_FAILUTE";

export const FETCH_PAYMENTS_DATA_REQUEST = "FETCH_PAYMENTS_DATA_REQUEST";
export const FETCH_PAYMENTS_DATA_SUCCESS = "FETCH_PAYMENTS_DATA_SUCCESS";
export const RESET_PAYMENTS_DATA = "RESET_PAYMENTS_DATA";

export const FETCH_PAYMENT_DATA_REQUEST = "FETCH_PAYMENT_DATA_REQUEST";
export const FETCH_PAYMENT_DATA_SUCCESS = "FETCH_PAYMENT_DATA_SUCCESS";
export const RESET_PAYMENT_DATA = "RESET_PAYMENT_DATA";

export const UPDATE_PAYMENT_REQUEST = "UPDATE_PAYMENT_REQUEST";
export const UPDATE_PAYMENT_SUCCESS = "UPDATE_PAYMENT_SUCCESS";

export const FETCH_PRODUCT_TAGS = "FETCH_PRODUCT_TAGS";
export const FETCH_PRODUCT_TAGS_SUCCESS = "FETCH_PRODUCT_TAGS_SUCCESS";

export const CREATE_PRODUCT_TAG_REQUEST = "CREATE_PRODUCT_TAG_REQUEST";
export const CREATE_PRODUCT_TAG_SUCCESS = "CREATE_PRODUCT_TAG_SUCCESS";
export const CREATE_PRODUCT_TAG_FAILURE = "CREATE_PRODUCT_TAG_FAILURE";

export const UPDATE_PRODUCT_TAG_REQUEST = "UPDATE_PRODUCT_TAG_REQUEST";
export const UPDATE_PRODUCT_TAG_SUCCESS = "UPDATE_PRODUCT_TAG_SUCCESS";
export const UPDATE_PRODUCT_TAG_FAILURE = "UPDATE_PRODUCT_TAG_FAILURE";

export const FETCH_ORDER_REPORTS = "FETCH_ORDER_REPORTS";
export const FETCH_ORDER_REPORTS_SUCCESS = "FETCH_ORDER_REPORTS_SUCCESS";
export const FETCH_ORDER_REPORTS_FAILURE = "FETCH_ORDER_REPORTS_FAILURE";

export const FETCH_TRANSLATIONS_REQUEST = "FETCH_TRANSLATIONS_REQUEST";
export const FETCH_TRANSLATIONS_SUCCESS = "FETCH_TRANSLATIONS_SUCCESS";

export const UPDATE_TRANSLATION_REQUEST = "UPDATE_TRANSLATION_REQUEST";
export const UPDATE_TRANSLATION_SUCCESS = "UPDATE_TRANSLATION_SUCCESS";
export const UPDATE_TRANSLATION_FAILURE = "UPDATE_TRANSLATION_FAILURE";

export const FETCH_TRANSLATION_REQUEST = "FETCH_TRANSLATION_REQUEST";
export const FETCH_TRANSLATION_SUCCESS = "FETCH_TRANSLATION_SUCCESS";
export const FETCH_TRANSLATION_FAILURE = "FETCH_TRANSLATION_FAILURE";

export const SAVE_URL_TRANSLATIONS = "SAVE_URL_TRANSLATIONS";
export const SAVE_URL_TRANSLATIONS_SUCCESS = "SAVE_URL_TRANSLATIONS_SUCCESS";
export const SAVE_URL_TRANSLATIONS_FAILURE = "SAVE_URL_TRANSLATIONS_FAILURE";

export const FETCH_NEIGHBOURHOODS_DATA_REQUEST =
  "FETCH_NEIGHBOURHOODS_DATA_REQUEST";
export const FETCH_NEIGHBOURHOODS_DATA_SUCCESS =
  "FETCH_NEIGHBOURHOODS_DATA_SUCCESS";

export const FETCH_NEIGHBOURHOOD_DATA_REQUEST =
  "FETCH_NEIGHBOURHOOD_DATA_REQUEST";
export const FETCH_NEIGHBOURHOOD_DATA_SUCCESS =
  "FETCH_NEIGHBOURHOOD_DATA_SUCCESS";

export const EXPORT_TRANSLATIONS_REQUEST = "EXPORT_TRANSLATIONS_REQUEST";
export const EXPORT_TRANSLATIONS_SUCCESS = "EXPORT_TRANSLATIONS_SUCCESS";
export const EXPORT_TRANSLATIONS_FAILURE = "EXPORT_TRANSLATIONS_FAILURE";

export const IMPORT_TRANSLATIONS_REQUEST = "IMPORT_TRANSLATIONS_REQUEST";
export const IMPORT_TRANSLATIONS_SUCCESS = "IMPORT_TRANSLATIONS_SUCCESS";
export const IMPORT_TRANSLATIONS_FAILURE = "IMPORT_TRANSLATIONS_FAILURE";

export const FETCH_THEMES_REQUEST = "FETCH_THEMES_REQUEST";
export const FETCH_THEMES_SUCCESS = "FETCH_THEMES_SUCCESS";

export const CREATE_THEME_REQUEST = "CREATE_THEME_REQUEST";
export const CREATE_THEME_SUCCESS = "CREATE_THEME_SUCCESS";
export const CREATE_THEME_FAILURE = "CREATE_THEME_FAILURE";

export const UPDATE_THEME_REQUEST = "UPDATE_THEME_REQUEST";
export const UPDATE_THEME_SUCCESS = "UPDATE_THEME_SUCCESS";
export const UPDATE_THEME_FAILURE = "UPDATE_THEME_FAILURE";

export const FETCH_CONTACTS_REQUEST = "FETCH_CONTACTS_REQUEST";
export const FETCH_CONTACTS_SUCCESS = "FETCH_CONTACTS_SUCCESS";
export const FETCH_CONTACTS_FAILURE = "FETCH_CONTACTS_FAILURE";

export const FETCH_APP_VERSIONS = "FETCH_APP_VERSIONS";
export const FETCH_APP_VERSIONS_SUCCESS = "FETCH_APP_VERSIONS_SUCCESS";

export const CREATE_APP_VERSION_REQUEST = "CREATE_APP_VERSION_REQUEST";
export const CREATE_APP_VERSION_SUCCESS = "CREATE_APP_VERSION_SUCCESS";
export const CREATE_APP_VERSION_FAILURE = "CREATE_APP_VERSION_FAILURE";

export const UPDATE_APP_VERSION_REQUEST = "UPDATE_APP_VERSION_REQUEST";
export const UPDATE_APP_VERSION_SUCCESS = "UPDATE_APP_VERSION_SUCCESS";
export const UPDATE_APP_VERSION_FAILURE = "UPDATE_APP_VERSION_FAILURE";

export const CREATE_APP_BUILD_REQUEST = "CREATE_APP_BUILD_REQUEST";
export const CREATE_APP_BUILD_SUCCESS = "CREATE_APP_BUILD_SUCCESS";
export const CREATE_APP_BUILD_FAILURE = "CREATE_APP_BUILD_FAILURE";

export const FETCH_GITHUB_DATA_REQUEST = "FETCH_GITHUB_DATA_REQUEST";
export const FETCH_GITHUB_DATA_SUCCESS = "FETCH_GITHUB_DATA_SUCCESS";

// ACTION TYPES FOR PLAY AUTOMATOR
export const PLAYSTORE_AUTOMATOR_POST_REQUEST =
  "PLAYSTORE_AUTOMATOR_POST_REQUEST";
export const PLAYSTORE_AUTOMATOR_POST_SUCCESS =
  "PLAYSTORE_AUTOMATOR_POST_SUCCESS";
export const PLAYSTORE_AUTOMATOR_POST_FAILURE =
  "PLAYSTORE_AUTOMATOR_POST_FAILURE";

// PLAY ACCOUNTS ADMIN DASHBOARD
export const FETCH_PLAY_ACCOUNTS = "FETCH_PLAY_ACCOUNTS";
export const FETCH_PLAY_ACCOUNTS_SUCCESS = "FETCH_PLAY_ACCOUNTS_SUCCESS";

export const FETCH_PLAY_ACCOUNT = "FETCH_PLAY_ACCOUNT";
export const FETCH_PLAY_ACCOUNT_SUCCESS = "FETCH_PLAY_ACCOUNT_SUCCESS";

export const CREATE_PLAY_ACCOUNT_REQUEST = "CREATE_PLAY_ACCOUNT_REQUEST";
export const CREATE_PLAY_ACCOUNT_SUCCESS = "CREATE_PLAY_ACCOUNT_SUCCESS";

export const UPDATE_PLAY_ACCOUNT_REQUEST = "UPDATE_PLAY_ACCOUNT_REQUEST";
export const UPDATE_PLAY_ACCOUNT_SUCCESS = "UPDATE_PLAY_ACCOUNT_SUCCESS";

export const FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS =
  "FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS";

export const APPEND_TO_OPEN_REFS_ARRAY = "APPEND_TO_OPEN_REFS_ARRAY";
export const RESET_OPEN_REFS_ARRAY = "RESET_OPEN_REFS_ARRAY";

export const PLAYSTORE_AUTOMATOR_OTP_REQUEST =
  "PLAYSTORE_AUTOMATOR_OTP_REQUEST";
export const PLAYSTORE_AUTOMATOR_OTP_SUCCESS =
  "PLAYSTORE_AUTOMATOR_OTP_SUCCESS";
export const PLAYSTORE_AUTOMATOR_OTP_FAILURE =
  "PLAYSTORE_AUTOMATOR_OTP_FAILURE";

export const CONNECT_HOSTNAME_DETAILS_REQUEST =
  "CONNECT_HOSTNAME_DETAILS_REQUEST";
export const CONNECT_HOSTNAME_DETAILS_SUCCESS =
  "CONNECT_HOSTNAME_DETAILS_SUCCESS";

export const CLEANUP_PLAY_ACCOUNT_REQUEST = "CLEANUP_PLAY_ACCOUNT_REQUEST";
export const CLEANUP_PLAY_ACCOUNT_SUCCESS = "CLEANUP_PLAY_ACCOUNT_SUCCESS";
export const CLEANUP_PLAY_ACCOUNT_FAILURE = "CLEANUP_PLAY_ACCOUNT_FAILURE";

export const ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_REQUEST =
  "ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_REQUEST";
export const ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_SUCCESS =
  "ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_SUCCESS";
export const ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_FAILURE =
  "ENSURE_FIREBASE_RELATED_SERVICES_PLAY_ACCOUNT_FAILURE";

export const CREATE_PLAY_APP_REQUEST = "CREATE_PLAY_APP_REQUEST";
export const CREATE_PLAY_APP_SUCCESS = "CREATE_PLAY_APP_SUCCESS";
export const CREATE_PLAY_APP_FAILURE = "CREATE_PLAY_APP_FAILURE";

export const CREATE_PLAY_APP_PREVIEW_SUCCESS =
  "CREATE_PLAY_APP_PREVIEW_SUCCESS";

export const UPDATE_PLAY_APP_REQUEST = "UPDATE_PLAY_APP_REQUEST";
export const UPDATE_PLAY_APP_SUCCESS = "UPDATE_PLAY_APP_SUCCESS";
export const UPDATE_PLAY_APP_FAILURE = "UPDATE_PLAY_APP_FAILURE";

export const UPDATE_PLAY_APP_PREVIEW_SUCCESS =
  "UPDATE_PLAY_APP_PREVIEW_SUCCESS";

export const FIREBASE_ACTION_REQUEST = "FIREBASE_ACTION_REQUEST";
export const FIREBASE_ACTION_SUCCESS = "FIREBASE_ACTION_SUCCESS";
export const FIREBASE_ACTION_FAILURE = "FIREBASE_ACTION_FAILURE";

export const AUTOMATE_PLAY_ACCOUNT_REQUEST = "AUTOMATE_PLAY_ACCOUNT_REQUEST";
export const AUTOMATE_PLAY_ACCOUNT_SUCCESS = "AUTOMATE_PLAY_ACCOUNT_SUCCESS";
export const AUTOMATE_PLAY_ACCOUNT_FAILURE = "AUTOMATE_PLAY_ACCOUNT_FAILURE";

export const PLAYSTORE_ACCOUNT_UNLOCK_REQUEST =
  "PLAYSTORE_ACCOUNT_UNLOCK_REQUEST";
export const PLAYSTORE_ACCOUNT_UNLOCK_FAILURE =
  "PLAYSTORE_ACCOUNT_UNLOCK_FAILURE";
export const PLAYSTORE_ACCOUNT_UNLOCK_SUCCESS =
  "PLAYSTORE_ACCOUNT_UNLOCK_SUCCESS";

export const FETCH_COUPON_OPTS_SUCCESS = "FETCH_COUPON_OPTS_SUCCESS";

export const FETCH_COUPON_CHANGES_SUCCESS = "FETCH_COUPON_CHANGES_SUCCESS";
export const FETCH_COUPON_CHANGES_FAILURE = "FETCH_COUPON_CHANGES_FAILURE";

export const RESET_COUPON_CHANGES = "RESET_COUPON_CHANGES";

export const CREATE_COUPON_REQUEST = "CREATE_COUPON_REQUEST";

export const FETCH_COUPONS_REQUEST = "FETCH_COUPONS_REQUEST";
export const FETCH_COUPONS_SUCCESS = "FETCH_COUPONS_SUCCESS";

export const DELETE_COUPONS_REQUEST = "DELETE_COUPONS_REQUEST";
export const DELETE_COUPONS_SUCCESS = "DELETE_COUPONS_SUCCESS";

export const FETCH_COUPON_FILTERDATA_SUCCESS =
  "FETCH_COUPON_FILTERDATA_SUCCESS";
export const FETCH_COUPON_FILTERDATA = "FETCH_COUPON_FILTERDATA";
export const COUPON_FILTER_CHANGE = "COUPON_FILTER_CHANGE";

export const GET_SPOT_INSTANCES_REQUEST = "GET_SPOT_INSTANCE_REQUEST";
export const GET_SPOT_INSTANCES_SUCCESS = "GET_SPOT_INSTANCE_REQUEST";
export const GET_SPOT_INSTANCES_FAILURE = "GET_SPOT_INSTANCE_REQUEST";

export const PAUSE_SPOT_INSTANCE_REQUEST = "PAUSE_SPOT_INSTANCE_REQUEST";
export const PAUSE_SPOT_INSTANCE_SUCCESS = "PAUSE_SPOT_INSTANCE_SUCCESS";
export const PAUSE_SPOT_INSTANCE_FAILURE = "PAUSE_SPOT_INSTANCE_FAILURE";

export const RESUME_SPOT_INSTANCE_REQUEST = "RESUME_SPOT_INSTANCE_REQUEST";
export const RESUME_SPOT_INSTANCE_SUCCESS = "RESUME_SPOT_INSTANCE_SUCCESS";
export const RESUME_SPOT_INSTANCE_FAILURE = "RESUME_SPOT_INSTANCE_FAILURE";

export const FETCH_SUBSCRIPTION_PLANS_REQUEST =
  "FETCH_SUBSCRIPTION_PLANS_REQUEST";
export const FETCH_SUBSCRIPTION_PLANS_SUCCESS =
  "FETCH_SUBSCRIPTION_PLANS_SUCCESS";

export const CREATE_SUBSCRIPTION_PLAN_REQUEST =
  "CREATE_SUBSCRIPTION_PLAN_REQUEST";
export const CREATE_SUBSCRIPTION_PLAN_SUCCESS =
  "CREATE_SUBSCRIPTION_PLAN_SUCCESS";
export const CREATE_SUBSCRIPTION_PLAN_FAILURE =
  "CREATE_SUBSCRIPTION_PLAN_FAILURE";

export const FETCH_ACCOUNT_REPORTS_REQUEST = "FETCH_ACCOUNT_REPORTS_REQUEST";
export const FETCH_ACCOUNT_REPORTS_SUCCESS = "FETCH_ACCOUNT_REPORTS_SUCCESS";

export const APPROVE_PLAY_APP_PUBLISH_REVIEW_REQUEST =
  "APPROVE_PLAY_APP_PUBLISH_REVIEW_REQUEST";
export const APPROVE_PLAY_APP_PUBLISH_REVIEW_SUCCESS =
  "APPROVE_PLAY_APP_PUBLISH_REVIEW_SUCCESS";
export const APPROVE_PLAY_APP_PUBLISH_REVIEW_FAILURE =
  "APPROVE_PLAY_APP_PUBLISH_REVIEW_FAILURE";

export const UPDATE_KYC_REQUEST = "UPDATE_KYC_REQUEST";
export const UPDATE_KYC_SUCCESS = "UPDATE_KYC_SUCCESS";
export const UPDATE_KYC_FAILURE = "UPDATE_KYC_FAILURE";

export const FETCH_WALLET_DATA_REQUEST = "FETCH_WALLET_DATA_REQUEST";
export const FETCH_WALLET_DATA_SUCCESS = "FETCH_WALLET_DATA_SUCCESS";
export const RESET_WALLET_DATA = "RESET_WALLET_DATA";

export const FETCH_IMAGES_REQUEST = "FETCH_IMAGES_REQUEST";
export const FETCH_IMAGES_SUCCESS = "FETCH_IMAGES_SUCCESS";
export const FETCH_IMAGES_FAILURE = "FETCH_IMAGES_FAILURE";

export const UPLOAD_IMAGE_REQUEST = "UPLOAD_IMAGE_REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "UPLOAD_IMAGE_SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "UPLOAD_IMAGE_FAILURE";

export const DELETE_IMAGE_REQUEST = "DELETE_IMAGE_REQUEST";
export const DELETE_IMAGE_SUCCESS = "DELETE_IMAGE_SUCCESS";
export const DELETE_IMAGE_FAILURE = "DELETE_IMAGE_FAILURE";

export const FETCH_EXPECTED_SHIPMENT_PRICE_REQUEST =
  "FETCH_EXPECTED_SHIPMENT_PRICE_REQUEST";
export const FETCH_EXPECTED_SHIPMENT_PRICE_FAILURE =
  "FETCH_EXPECTED_SHIPMENT_PRICE_FAILURE";
export const FETCH_EXPECTED_SHIPMENT_PRICE_SUCCESS =
  "FETCH_EXPECTED_SHIPMENT_PRICE_SUCCESS";

export const RESET_EXPECT_SHIPMENT_PRICE = "RESET_EXPECT_SHIPMENT_PRICE";

export const FETCH_SHIPMENTS_REQUEST = "FETCH_SHIPMENTS_REQUEST";
export const FETCH_SHIPMENTS_SUCCESS = "FETCH_SHIPMENTS_SUCCESS";
export const FETCH_SHIPMENTS_FAILURE = "FETCH_SHIPMENTS_FAILURE";

export const RESET_SHIPMENTS_DATA = "RESET_SHIPMENTS_DATA";

export const ALLOT_ROUTE_PLAN_TO_BATCH_REQUEST =
  "ALLOT_ROUTE_PLAN_TO_BATCH_REQUEST";
export const ALLOT_ROUTE_PLAN_TO_BATCH_SUCCESS =
  "ALLOT_ROUTE_PLAN_TO_BATCH_SUCCESS";
export const ALLOT_ROUTE_PLAN_TO_BATCH_FAILURE =
  "ALLOT_ROUTE_PLAN_TO_BATCH_FAILURE";

export const SAVE_BATCH_PLANS_REQUEST = "SAVE_BATCH_PLANS_REQUEST";
export const SAVE_BATCH_PLANS_SUCCESS = "SAVE_BATCH_PLANS_SUCCESS";
export const SAVE_BATCH_PLANS_FAILURE = "SAVE_BATCH_PLANS_FAILURE";

export const FETCH_THIRD_PARTY_SHIPMENTS_REQUEST =
  "FETCH_THIRD_PARTY_SHIPMENTS_REQUEST";
export const FETCH_THIRD_PARTY_SHIPMENTS_SUCCESS =
  "FETCH_THIRD_PARTY_SHIPMENTS_SUCCESS";
export const FETCH_THIRD_PARTY_SHIPMENTS_FAILURE =
  "FETCH_THIRD_PARTY_SHIPMENTS_FAILURE";

export const ASSIGN_SHIPMENTS_TO_THIRD_PARTY_REQUEST =
  "ASSIGN_SHIPMENTS_TO_THIRD_PARTY_REQUEST";
export const ASSIGN_SHIPMENTS_TO_THIRD_PARTY_SUCCESS =
  "ASSIGN_SHIPMENTS_TO_THIRD_PARTY_SUCCESS";
export const ASSIGN_SHIPMENTS_TO_THIRD_PARTY_FAILURE =
  "ASSIGN_SHIPMENTS_TO_THIRD_PARTY_FAILURE";

export const RESET_SHIPMENT_INSTANCES_DATA = "RESET_SHIPMENT_INSTANCES_DATA";

export const FETCH_SHIPMENT_INSTANCES_REQUEST =
  "FETCH_SHIPMENT_INSTANCES_REQUEST";
export const FETCH_SHIPMENT_INSTANCES_SUCCESS =
  "FETCH_SHIPMENT_INSTANCES_SUCCESS";
export const FETCH_SHIPMENT_INSTANCES_FAILURE =
  "FETCH_SHIPMENT_INSTANCES_FAILURE";

export const RESET_SHIPMENT_DATA = "RESET_SHIPMENT_DATA";

export const FETCH_SHIPMENT_REQUEST = "FETCH_SHIPMENT_REQUEST";
export const FETCH_SHIPMENT_SUCCESS = "FETCH_SHIPMENT_SUCCESS";
export const FETCH_SHIPMENT_FAILURE = "FETCH_SHIPMENT_FAILURE";

export const RESET_DELIVERY_STAFF_DATA = "RESET_DELIVERY_STAFF_DATA";

export const FETCH_DELIVERY_STAFF_REQUEST = "FETCH_DELIVERY_STAFF_REQUEST";
export const FETCH_DELIVERY_STAFF_SUCCESS = "FETCH_DELIVERY_STAFF_SUCCESS";
export const FETCH_DELIVERY_STAFF_FAILURE = "FETCH_DELIVERY_STAFF_FAILURE";

export const RESET_DELIVERY_PERSON_DATA = "RESET_DELIVERY_PERSON_DATA";

export const CREATE_DELIVERY_PERSON_REQUEST = "CREATE_DELIVERY_PERSON_REQUEST";
export const CREATE_DELIVERY_PERSON_SUCCESS = "CREATE_DELIVERY_PERSON_SUCCESS";
export const CREATE_DELIVERY_PERSON_FAILURE = "CREATE_DELIVERY_PERSON_FAILURE";

export const FETCH_DELIVERY_PERSON_REQUEST = "FETCH_DELIVERY_PERSON_REQUEST";
export const FETCH_DELIVERY_PERSON_SUCCESS = "FETCH_DELIVERY_PERSON_SUCCESS";
export const FETCH_DELIVERY_PERSON_FAILURE = "FETCH_DELIVERY_PERSON_FAILURE";

export const EDIT_DELIVERY_PERSON_REQUEST = "EDIT_DELIVERY_PERSON_REQUEST";
export const EDIT_DELIVERY_PERSON_SUCCESS = "EDIT_DELIVERY_PERSON_SUCCESS";
export const EDIT_DELIVERY_PERSON_FAILURE = "EDIT_DELIVERY_PERSON_FAILURE";

export const DELETE_DELIVERY_PERSON_REQUEST = "DELETE_DELIVERY_PERSON_REQUEST";
export const DELETE_DELIVERY_PERSON_SUCCESS = "DELETE_DELIVERY_PERSON_SUCCESS";
export const DELETE_DELIVERY_PERSON_FAILURE = "DELETE_DELIVERY_PERSON_FAILURE";

export const RESET_WAREHOUSES_DATA = "RESET_WAREHOUSES_DATA";

export const FETCH_WAREHOUSES_REQUEST = "FETCH_WAREHOUSES_REQUEST";
export const FETCH_WAREHOUSES_SUCCESS = "FETCH_WAREHOUSES_SUCCESS";
export const FETCH_WAREHOUSES_FAILURE = "FETCH_WAREHOUSES_FAILURE";

export const RESET_WAREHOUSE_DATA = "RESET_WAREHOUSE_DATA";

export const FETCH_WAREHOUSE_REQUEST = "FETCH_WAREHOUSE_REQUEST";
export const FETCH_WAREHOUSE_SUCCESS = "FETCH_WAREHOUSE_SUCCESS";
export const FETCH_WAREHOUSE_FAILURE = "FETCH_WAREHOUSE_FAILURE";

export const EDIT_WAREHOUSE_REQUEST = "EDIT_WAREHOUSE_REQUEST";
export const EDIT_WAREHOUSE_SUCCESS = "EDIT_WAREHOUSE_SUCCESS";
export const EDIT_WAREHOUSE_FAILURE = "EDIT_WAREHOUSE_FAILURE";

export const CREATE_WAREHOUSE_REQUEST = "CREATE_WAREHOUSE_REQUEST";
export const CREATE_WAREHOUSE_SUCCESS = "CREATE_WAREHOUSE_SUCCESS";
export const CREATE_WAREHOUSE_FAILURE = "CREATE_WAREHOUSE_FAILURE";

export const CREATE_WAREHOUSE_VENDOR_REQUEST =
  "CREATE_WAREHOUSE_VENDOR_REQUEST";
export const CREATE_WAREHOUSE_VENDOR_SUCCESS =
  "CREATE_WAREHOUSE_VENDOR_SUCCESS";
export const CREATE_WAREHOUSE_VENDOR_FAILURE =
  "CREATE_WAREHOUSE_VENDOR_FAILURE";

export const DELETE_WAREHOUSE_REQUEST = "DELETE_WAREHOUSE_REQUEST";
export const DELETE_WAREHOUSE_SUCCESS = "DELETE_WAREHOUSE_SUCCESS";
export const DELETE_WAREHOUSE_FAILURE = "DELETE_WAREHOUSE_FAILURE";

export const RESET_DELIVERY_VENDORS_DATA = "RESET_DELIVERY_VENDORS_DATA";

export const FETCH_DELIVERY_VENDORS_REQUEST = "FETCH_DELIVERY_VENDORS_REQUEST";
export const FETCH_DELIVERY_VENDORS_SUCCESS = "FETCH_DELIVERY_VENDORS_SUCCESS";
export const FETCH_DELIVERY_VENDORS_FAILURE = "FETCH_DELIVERY_VENDORS_FAILURE";

export const CREATE_DELIVERY_VENDOR_REQUEST = "CREATE_DELIVERY_VENDOR_REQUEST";
export const CREATE_DELIVERY_VENDOR_SUCCESS = "CREATE_DELIVERY_VENDOR_SUCCESS";
export const CREATE_DELIVERY_VENDOR_FAILURE = "CREATE_DELIVERY_VENDOR_FAILURE";

export const RESET_DELIVERY_VENDOR_DATA = "RESET_DELIVERY_VENDOR_DATA";

export const FETCH_DELIVERY_VENDOR_REQUEST = "FETCH_DELIVERY_VENDOR_REQUEST";
export const FETCH_DELIVERY_VENDOR_SUCCESS = "FETCH_DELIVERY_VENDOR_SUCCESS";
export const FETCH_DELIVERY_VENDOR_FAILURE = "FETCH_DELIVERY_VENDOR_FAILURE";

export const RESET_USER_VERIFICATIONS_DATA = "RESET_USER_VERIFICATIONS_DATA";

export const FETCH_USER_VERIFICATIONS_REQUEST =
  "FETCH_USER_VERIFICATIONS_REQUEST";
export const FETCH_USER_VERIFICATIONS_SUCCESS =
  "FETCH_USER_VERIFICATIONS_SUCCESS";
export const FETCH_USER_VERIFICATIONS_FAILURE =
  "FETCH_USER_VERIFICATIONS_FAILURE";

export const RESET_DELIVERY_PERSON_DISTANCE_DATA =
  "RESET_DELIVERY_PERSON_DISTANCE_DATA";

export const FETCH_DELIVERY_PERSON_DISTANCE_REQUEST =
  "FETCH_DELIVERY_PERSON_DISTANCE_REQUEST";
export const FETCH_DELIVERY_PERSON_DISTANCE_SUCCESS =
  "FETCH_DELIVERY_PERSON_DISTANCE_SUCCESS";
export const FETCH_DELIVERY_PERSON_DISTANCE_FAILURE =
  "FETCH_DELIVERY_PERSON_DISTANCE_FAILURE";

export const RESET_PICKUPS_DATA = "RESET_PICKUPS_DATA";

export const FETCH_PICKUPS_REQUEST = "FETCH_PICKUPS_REQUEST";
export const FETCH_PICKUPS_SUCCESS = "FETCH_PICKUPS_SUCCESS";
export const FETCH_PICKUPS_FAILURE = "FETCH_PICKUPS_FAILURE";

export const FETCH_LOCATIONS_BY_BATCH_REQUEST =
  "FETCH_LOCATIONS_BY_BATCH_REQUEST";
export const FETCH_LOCATIONS_BY_BATCH_SUCCESS =
  "FETCH_LOCATIONS_BY_BATCH_SUCCESS";
export const FETCH_LOCATIONS_BY_BATCH_FAILURE =
  "FETCH_LOCATIONS_BY_BATCH_FAILURE";

export const ASSIGN_PICKUPS_REQUEST = "ASSIGN_PICKUPS_REQUEST";
export const ASSIGN_PICKUPS_SUCCESS = "ASSIGN_PICKUPS_SUCCESS";
export const ASSIGN_PICKUPS_FAILURE = "ASSIGN_PICKUPS_FAILURE";

export const ALLOT_PICKUPS_TO_WAREHOUSE_REQUEST =
  "ALLOT_PICKUPS_TO_WAREHOUSE_REQUEST";
export const ALLOT_PICKUPS_TO_WAREHOUSE_SUCCESS =
  "ALLOT_PICKUPS_TO_WAREHOUSE_SUCCESS";
export const ALLOT_PICKUPS_TO_WAREHOUSE_FAILURE =
  "ALLOT_PICKUPS_TO_WAREHOUSE_FAILURE";

export const RESET_ROUTE_PLAN_DATA = "RESET_ROUTE_PLAN_DATA";

export const FETCH_ROUTE_PLAN_REQUEST = "FETCH_ROUTE_PLAN_REQUEST";
export const FETCH_ROUTE_PLAN_SUCCESS = "FETCH_ROUTE_PLAN_SUCCESS";
export const FETCH_ROUTE_PLAN_FAILURE = "FETCH_ROUTE_PLAN_FAILURE";

export const CREATE_ROUTE_PLAN_REQUEST = "CREATE_ROUTE_PLAN_REQUEST";
export const CREATE_ROUTE_PLAN_SUCCESS = "CREATE_ROUTE_PLAN_SUCCESS";
export const CREATE_ROUTE_PLAN_FAILURE = "CREATE_ROUTE_PLAN_FAILURE";

export const UPDATE_ROUTE_PLAN_REQUEST = "UPDATE_ROUTE_PLAN_REQUEST";
export const UPDATE_ROUTE_PLAN_SUCCESS = "UPDATE_ROUTE_PLAN_SUCCESS";
export const UPDATE_ROUTE_PLAN_FAILURE = "UPDATE_ROUTE_PLAN_FAILURE";

export const FETCH_ROUTE_PLAN_FOR_BATCH_REQUEST =
  "FETCH_ROUTE_PLAN_FOR_BATCH_REQUEST";
export const FETCH_ROUTE_PLAN_FOR_BATCH_SUCCESS =
  "FETCH_ROUTE_PLAN_FOR_BATCH_SUCCESS";
export const FETCH_ROUTE_PLAN_FOR_BATCH_FAILURE =
  "FETCH_ROUTE_PLAN_FOR_BATCH_FAILURE";

export const RESET_ROUTE_INSTANCES_DATA = "RESET_ROUTE_INSTANCES_DATA";

export const FETCH_ROUTE_INSTANCES_REQUEST = "FETCH_ROUTE_INSTANCES_REQUEST";
export const FETCH_ROUTE_INSTANCES_SUCCESS = "FETCH_ROUTE_INSTANCES_SUCCESS";
export const FETCH_ROUTE_INSTANCES_FAILURE = "FETCH_ROUTE_INSTANCES_FAILURE";

export const ASSIGN_ROUTE_INSTANCE_REQUEST = "ASSIGN_ROUTE_INSTANCE_REQUEST";
export const ASSIGN_ROUTE_INSTANCE_SUCCESS = "ASSIGN_ROUTE_INSTANCE_SUCCESS";
export const ASSIGN_ROUTE_INSTANCE_FAILURE = "ASSIGN_ROUTE_INSTANCE_FAILURE";

export const ALLOT_ROUTE_INSTANCES_REQUEST = "ALLOT_ROUTE_INSTANCES_REQUEST";
export const ALLOT_ROUTE_INSTANCES_SUCCESS = "ALLOT_ROUTE_INSTANCES_SUCCESS";
export const ALLOT_ROUTE_INSTANCES_FAILURE = "ALLOT_ROUTE_INSTANCES_FAILURE";

export const RESET_BATCHES_DATA = "RESET_BATCHES_DATA";

export const RESET_BATCHES_LOCATIONS_DATA = "RESET_BATCHES_LOCATIONS_DATA";

export const FETCH_BATCHES_REQUEST = "FETCH_BATCHES_REQUEST";
export const FETCH_BATCHES_SUCCESS = "FETCH_BATCHES_SUCCESS";
export const FETCH_BATCHES_FAILURE = "FETCH_BATCHES_FAILURE";

export const RESET_BATCH_DATA = "RESET_BATCH_DATA";

export const CREATE_BATCH_REQUEST = "CREATE_BATCH_REQUEST";
export const CREATE_BATCH_SUCCESS = "CREATE_BATCH_SUCCESS";
export const CREATE_BATCH_FAILURE = "CREATE_BATCH_FAILURE";

export const RESET_BATCH_PLANS_DATA = "RESET_BATCH_PLANS_DATA";

export const FETCH_BATCH_PLANS_REQUEST = "FETCH_BATCH_PLANS_REQUEST";
export const FETCH_BATCH_PLANS_SUCCESS = "FETCH_BATCH_PLANS_SUCCESS";
export const FETCH_BATCH_PLANS_FAILURE = "FETCH_BATCH_PLANS_FAILURE";

export const CREATE_BATCH_PLANS_REQUEST = "CREATE_BATCH_PLANS_REQUEST";
export const CREATE_BATCH_PLANS_SUCCESS = "CREATE_BATCH_PLANS_SUCCESS";
export const CREATE_BATCH_PLANS_FAILURE = "CREATE_BATCH_PLANS_FAILURE";

export const MODIFY_BATCH_PLANS_REQUEST = "MODIFY_BATCH_PLANS_REQUEST";
export const MODIFY_BATCH_PLANS_SUCCESS = "MODIFY_BATCH_PLANS_SUCCESS";
export const MODIFY_BATCH_PLANS_FAILURE = "MODIFY_BATCH_PLANS_FAILURE";

export const RESET_TOURS_DATA = "RESET_TOURS_DATA";

export const ASSIGN_TOURS_REQUEST = "ASSIGN_TOURS_REQUEST";
export const ASSIGN_TOURS_SUCCESS = "ASSIGN_TOURS_SUCCESS";
export const ASSIGN_TOURS_FAILURE = "ASSIGN_TOURS_FAILURE";

export const FETCH_BATCH_DETAILS_REQUEST = "FETCH_BATCH_DETAILS_REQUEST";
export const FETCH_BATCH_DETAILS_SUCCESS = "FETCH_BATCH_DETAILS_SUCCESS";
export const FETCH_BATCH_DETAILS_FAILURE = "FETCH_BATCH_DETAILS_FAILURE";

export const FETCH_BATCH_REQUEST = "FETCH_BATCH_REQUEST";
export const FETCH_BATCH_SUCCESS = "FETCH_BATCH_SUCCESS";
export const FETCH_BATCH_FAILURE = "FETCH_BATCH_FAILURE";

export const RESET_PLANS_DATA = "RESET_PLANS_DATA";

export const FETCH_PLANS_REQUEST = "FETCH_PLANS_REQUEST";
export const FETCH_PLANS_SUCCESS = "FETCH_PLANS_SUCCESS";
export const FETCH_PLANS_FAILURE = "FETCH_PLANS_FAILURE";

export const ASSIGN_RIDER_TO_TOUR = "ASSIGN_RIDER_TO_TOUR";

export const RESET_TRACKING_DATA = "RESET_TRACKING_DATA";

export const FETCH_THIRD_PARTY_SHIPMENT_TRACKING_REQUEST =
  "FETCH_THIRD_PARTY_SHIPMENT_TRACKING_REQUEST";
export const FETCH_THIRD_PARTY_SHIPMENT_TRACKING_SUCCESS =
  "FETCH_THIRD_PARTY_SHIPMENT_TRACKING_SUCCESS";
export const FETCH_THIRD_PARTY_SHIPMENT_TRACKING_FAILURE =
  "FETCH_THIRD_PARTY_SHIPMENT_TRACKING_FAILURE";

export const POST_TRACKING_FORM_REQUEST = "POST_TRACKING_FORM_REQUEST";
export const POST_TRACKING_FORM_SUCCESS = "POST_TRACKING_FORM_SUCCESS";
export const POST_TRACKING_FORM_FAILURE = "POST_TRACKING_FORM_FAILURE";

export const CREATE_PICKUP_REQUEST_REQUEST = "CREATE_PICKUP_REQUEST_REQUEST";
export const CREATE_PICKUP_REQUEST_SUCCESS = "CREATE_PICKUP_REQUEST_SUCCESS";
export const CREATE_PICKUP_REQUEST_FAILURE = "CREATE_PICKUP_REQUEST_FAILURE";

export const FETCH_PACKAGING_SLIPS_REQUEST = "FETCH_PACKAGING_SLIPS_REQUEST";
export const FETCH_PACKAGING_SLIPS_SUCCESS = "FETCH_PACKAGING_SLIPS_SUCCESS";
export const FETCH_PACKAGING_SLIPS_FAILURE = "FETCH_PACKAGING_SLIPS_FAILURE";

export const ADD_DATA_FOR_PACKAGING_SLIPS = "ADD_DATA_FOR_PACKAGING_SLIPS";

export const RESET_ADDRESS_DATA = "RESET_ADDRESS_DATA";

export const FETCH_ADDRESS_REQUEST = "FETCH_ADDRESS_REQUEST";
export const FETCH_ADDRESS_SUCCESS = "FETCH_ADDRESS_SUCCESS";
export const FETCH_ADDRESS_FAILURE = "FETCH_ADDRESS_FAILURE";

export const UPDATE_ADDRESS_REQUEST = "UPDATE_ADDRESS_REQUEST";
export const UPDATE_ADDRESS_SUCCESS = "UPDATE_ADDRESS_SUCCESS";
export const UPDATE_ADDRESS_FAILURE = "UPDATE_ADDRESS_FAILURE";

export const CREATE_ADDRESS_REQUEST = "CREATE_ADDRESS_REQUEST";
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS";
export const CREATE_ADDRESS_FAILURE = "CREATE_ADDRESS_FAILURE";

export const FORCE_UPDATE_SHIPMENT_REQUEST = "FORCE_UPDATE_SHIPMENT_REQUEST";
export const FORCE_UPDATE_SHIPMENT_SUCCESS = "FORCE_UPDATE_SHIPMENT_SUCCESS";
export const FORCE_UPDATE_SHIPMENT_FAILURE = "FORCE_UPDATE_SHIPMENT_FAILURE";

export const UPDATE_SHIPMENT_REQUEST = "UPDATE_SHIPMENT_REQUEST";
export const UPDATE_SHIPMENT_SUCCESS = "UPDATE_SHIPMENT_SUCCESS";
export const UPDATE_SHIPMENT_FAILURE = "UPDATE_SHIPMENT_FAILURE";

export const FETCH_SHIPMENT_NDR_STATUS_REQUEST = "FETCH_SHIPMENT_NDR_STATUS";
export const FETCH_SHIPMENT_NDR_STATUS_SUCCESS = "FETCH_SHIPMENT_NDR_STATUS";
export const FETCH_SHIPMENT_NDR_STATUS_FAILURE = "FETCH_SHIPMENT_NDR_STATUS";

export const UPDATE_SHIPMENT_NDR_REQUEST = "UPDATE_SHIPMENT_NDR_REQUEST";
export const UPDATE_SHIPMENT_NDR_SUCCESS = "UPDATE_SHIPMENT_NDR_SUCCESS";
export const UPDATE_SHIPMENT_NDR_FAILURE = "UPDATE_SHIPMENT_NDR_FAILURE";

export const UPDATE_SHIPMENT_ADDRESS_REQUEST =
  "UPDATE_SHIPMENT_ADDRESS_REQUEST";
export const UPDATE_SHIPMENT_ADDRESS_SUCCESS =
  "UPDATE_SHIPMENT_ADDRESS_SUCCESS";
export const UPDATE_SHIPMENT_ADDRESS_FAILURE =
  "UPDATE_SHIPMENT_ADDRESS_FAILURE";

export const FETCH_SCANCODES_FAILURE = "FETCH_SCANCODES_FAILURE";
export const FETCH_SCANCODES_REQUEST = "FETCH_SCANCODES_REQUEST";
export const FETCH_SCANCODES_SUCCESS = "FETCH_SCANCODES_SUCCESS";
export const RESET_SCANCODES = "RESET_SCANCODES";

export const UPDATE_SCANCODES_REQUEST = "UPDATE_SCANCODES_REQUEST";
export const UPDATE_SCANCODES_SUCCESS = "UPDATE_SCANCODES_SUCCESS";

export const FETCH_SCANCODE_REQUEST = "FETCH_SCANCODE_REQUEST";
export const FETCH_SCANCODE_SUCCESS = "FETCH_SCANCODE_SUCCESS";
export const RESET_SCANCODE = "RESET_SCANCODE";

export const UPDATE_SCANCODE_REQUEST = "UPDATE_SCANCODE_REQUEST";
export const UPDATE_SCANCODE_SUCCESS = "UPDATE_SCANCODE_SUCCESS";

export const FETCH_IGM_REQUEST = "FETCH_IGM_REQUEST";
export const FETCH_IGM_SUCCESS = "FETCH_IGM_SUCCESS";

export const FETCH_IGM_ISSUE_REQUEST = "FETCH_IGM_ISSUE_REQUEST";
export const FETCH_IGM_ISSUE_SUCCESS = "FETCH_IGM_ISSUE_SUCCESS";

export const FETCH_IGM_REPORT_REQUEST = "FETCH_IGM_REPORT_REQUEST";
export const FETCH_IGM_REPORT_SUCCESS = "FETCH_IGM_REPORT_SUCCESS";

export const UPLOAD_SCANNED_CODES_REQUEST = "UPLOAD_SCANNED_CODES_REQUEST";
export const UPLOAD_SCANNED_CODES_SUCCESS = "UPLOAD_SCANNED_CODES_SUCCESS";
export const UPLOAD_SCANNED_CODES_FAILURE = "UPLOAD_SCANNED_CODES_FAILURE";

export const FETCH_ONDC_SELLER_VERIFICATION_REQUEST =
  "FETCH_ONDC_SELLER_VERIFICATION_REQUEST";
export const FETCH_ONDC_SELLER_VERIFICATION_SUCCESS =
  "FETCH_ONDC_SELLER_VERIFICATION_SUCCESS";
export const FETCH_ONDC_SELLER_VERIFICATION_FAILURE =
  "FETCH_ONDC_SELLER_VERIFICATION_FAILURE";
export const RESET_ONDC_SELLER_VERIFICATION_DATA =
  "RESET_ONDC_SELLER_VERIFICATION_DATA";
