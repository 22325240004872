import {
  APPROVE_PLAY_APP_PUBLISH_REVIEW_FAILURE,
  APPROVE_PLAY_APP_PUBLISH_REVIEW_REQUEST,
  APPROVE_PLAY_APP_PUBLISH_REVIEW_SUCCESS,
  BLOCK_UNBLOCK_FAILURE,
  BLOCK_UNBLOCK_REQUEST,
  BLOCK_UNBLOCK_SUCCESS,
  CHANGE_SUBSCRIPTION_FAILURE,
  CHANGE_SUBSCRIPTION_REQUEST,
  CHANGE_SUBSCRIPTION_SUCCESS,
  CONNECT_HOSTNAME_DETAILS_REQUEST,
  CONNECT_HOSTNAME_DETAILS_SUCCESS,
  CREATE_ACCOUNT_REQUEST,
  CREATE_ACCOUNT_SUCCESS,
  EXTEND_SUBSCRIPTION_REQUEST,
  EXTEND_SUBSCRIPTION_SUCCESS,
  FETCH_ACCOUNT_FAILURE,
  FETCH_ACCOUNT_REPORTS_REQUEST,
  FETCH_ACCOUNT_REPORTS_SUCCESS,
  FETCH_ACCOUNT_REQUEST,
  FETCH_ACCOUNT_SUCCESS,
  FETCH_ACCOUNTS_FAILURE,
  FETCH_ACCOUNTS_REQUEST,
  FETCH_ACCOUNTS_SUCCESS,
  FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS,
  FIREBASE_ACTION_FAILURE,
  FIREBASE_ACTION_REQUEST,
  FIREBASE_ACTION_SUCCESS,
  PLAYAPP_UPLOADS_BLOCK_UNBLOCK_FAILURE,
  PLAYAPP_UPLOADS_BLOCK_UNBLOCK_REQUEST,
  PLAYAPP_UPLOADS_BLOCK_UNBLOCK_SUCCESS,
  PLAYSTORE_AUTOMATOR_OTP_FAILURE,
  PLAYSTORE_AUTOMATOR_OTP_REQUEST,
  PLAYSTORE_AUTOMATOR_OTP_SUCCESS,
  REFRESH_USAGE_DATA_FAILURE,
  REFRESH_USAGE_DATA_REQUEST,
  REFRESH_USAGE_DATA_SUCCESS,
  UPDATE_ACCOUNT_REQUEST,
  UPDATE_ACCOUNT_SUCCESS,
  UPDATE_KYC_FAILURE,
  UPDATE_KYC_REQUEST,
  UPDATE_KYC_SUCCESS,
  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPLOAD_SCANNED_CODES_FAILURE,
  UPLOAD_SCANNED_CODES_REQUEST,
  UPLOAD_SCANNED_CODES_SUCCESS
} from "../constants/actionTypes";
import { createReducer } from "../utils";

const initialState = {
  accounts: [],
  accountReports: [],
  page: 1,
  count: 0,
  isFetching: true,
  isRefreshing: true,
  account: {},
  isAccountFetching: true,
  isFetchingReports: false,
  isAccountBlocking: false,
  isPlayAppUploadsBlocking: false,
  isExtendingSubscription: false,
  isChangingSubscription: false,
  isCreatingOrUpdating: false,
  automatorSession: {
    active: false,
    otpRequested: false,
    otpSuccessful: false,
    otpWrong: false,
    sessionFailed: false
  },
  reportStatus: {},
  isUploadingScannedCodes: false
};

export const accountsReducer = createReducer(initialState, {
  [FETCH_ACCOUNTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: true
    };
  },
  [FETCH_ACCOUNTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetching: false
    };
  },
  [FETCH_ACCOUNTS_FAILURE]: state => {
    return {
      ...state,
      isFetching: false
    };
  },
  [FETCH_ACCOUNT_REPORTS_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReports: true
    };
  },
  [FETCH_ACCOUNT_REPORTS_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isFetchingReports: false
    };
  },
  [FETCH_ACCOUNT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isAccountFetching: true
    };
  },
  [FETCH_ACCOUNT_SUCCESS]: (state, payload) => {
    // Easy way to access/update razorpay releated keys
    payload.account.keys.razorpayKey =
      payload.account.keys?.razorpay?.key ?? "";
    payload.account.keys.razorpaySecret =
      payload.account.keys?.razorpay?.secret ?? "";
    payload.account.keys.razorpayWebhookSignature =
      payload.account.keys?.razorpay?.webhookSignature ?? "";
    delete payload.account.keys.razorpay;

    return {
      ...state,
      account: {
        ...state.account,
        [String(payload.account._id)]: payload.account
      },
      isAccountFetching: false
    };
  },
  [FETCH_ACCOUNT_FAILURE]: state => {
    return {
      ...state,
      isAccountFetching: false,
      error: "Account Fetch Failed"
    };
  },
  [EXTEND_SUBSCRIPTION_REQUEST]: state => {
    return {
      ...state,
      isExtendingSubscription: true
    };
  },
  [EXTEND_SUBSCRIPTION_SUCCESS]: state => {
    return {
      ...state,
      isExtendingSubscription: false
    };
  },
  [CHANGE_SUBSCRIPTION_REQUEST]: state => {
    return {
      ...state,
      isChangingSubscription: true
    };
  },
  [CHANGE_SUBSCRIPTION_SUCCESS]: state => {
    return {
      ...state,
      isChangingSubscription: false,
      error: null
    };
  },
  [CHANGE_SUBSCRIPTION_FAILURE]: (state, payload) => {
    return {
      ...state,
      isChangingSubscription: false,
      error: JSON.parse(payload).error
    };
  },
  [UPDATE_SUBSCRIPTION_REQUEST]: state => {
    return {
      ...state,
      isChangingSubscription: true
    };
  },
  [UPDATE_SUBSCRIPTION_SUCCESS]: state => {
    return {
      ...state,
      isChangingSubscription: false
    };
  },
  [CREATE_ACCOUNT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [CREATE_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: false
    };
  },
  [UPDATE_ACCOUNT_REQUEST]: (state, payload) => {
    return {
      ...state,
      ...payload,
      isCreatingOrUpdating: true
    };
  },
  [UPDATE_ACCOUNT_SUCCESS]: (state, payload) => {
    return {
      ...state,
      account: {
        ...state.account,
        [String(payload.account._id)]: payload.account
      },
      isCreatingOrUpdating: false
    };
  },
  [BLOCK_UNBLOCK_REQUEST]: state => {
    return {
      ...state,
      isAccountBlocking: true
    };
  },
  [BLOCK_UNBLOCK_SUCCESS]: state => {
    return {
      ...state,
      isAccountBlocking: false,
      accountBlocked: true
    };
  },
  [BLOCK_UNBLOCK_FAILURE]: state => {
    return {
      ...state,
      isAccountBlocking: false,
      error: "Account Block Failed"
    };
  },
  [PLAYAPP_UPLOADS_BLOCK_UNBLOCK_REQUEST]: state => {
    return {
      ...state,
      isPlayAppUploadsBlocking: true
    };
  },
  [PLAYAPP_UPLOADS_BLOCK_UNBLOCK_SUCCESS]: state => {
    return {
      ...state,
      isPlayAppUploadsBlocking: false
    };
  },
  [PLAYAPP_UPLOADS_BLOCK_UNBLOCK_FAILURE]: state => {
    return {
      ...state,
      isPlayAppUploadsBlocking: false,
      error: "Account Playapp Uploades block/unblock Failed"
    };
  },
  [FETCH_AUTOMATOR_SESSION_STATUS_SUCCESS]: (state, payload) => {
    if (!payload.data) return state;
    return {
      ...state,
      automatorSession: payload.data
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [PLAYSTORE_AUTOMATOR_OTP_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },

  [CONNECT_HOSTNAME_DETAILS_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [CONNECT_HOSTNAME_DETAILS_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },

  [FIREBASE_ACTION_REQUEST]: state => {
    return {
      ...state,
      isCreatingOrUpdating: true
    };
  },
  [FIREBASE_ACTION_SUCCESS]: state => {
    return {
      ...state,
      isCreatingOrUpdating: false
    };
  },
  [FIREBASE_ACTION_FAILURE]: (state, payload) => {
    return {
      ...state,
      isCreatingOrUpdating: false,
      error: payload.error
    };
  },
  [REFRESH_USAGE_DATA_REQUEST]: state => {
    return {
      ...state,
      isRefreshing: true
    };
  },
  [REFRESH_USAGE_DATA_SUCCESS]: state => {
    return {
      ...state,
      isRefreshing: false
    };
  },
  [REFRESH_USAGE_DATA_FAILURE]: state => {
    return {
      ...state,
      isRefreshing: false,
      error: "Refresh Usage Data Failed"
    };
  },
  [APPROVE_PLAY_APP_PUBLISH_REVIEW_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [APPROVE_PLAY_APP_PUBLISH_REVIEW_SUCCESS]: state => {
    return {
      ...state,
      isFetching: false
    };
  },
  [APPROVE_PLAY_APP_PUBLISH_REVIEW_FAILURE]: state => {
    return {
      ...state,
      isFetching: false
    };
  },
  [UPDATE_KYC_REQUEST]: state => {
    return {
      ...state,
      isFetching: true
    };
  },
  [UPDATE_KYC_SUCCESS]: state => {
    return {
      ...state,
      isFetching: false
    };
  },
  [UPDATE_KYC_FAILURE]: state => {
    return {
      ...state,
      isFetching: false
    };
  },
  [UPLOAD_SCANNED_CODES_REQUEST]: state => {
    return {
      ...state,
      isUploadingScannedCodes: true
    };
  },
  [UPLOAD_SCANNED_CODES_SUCCESS]: state => {
    return {
      ...state,
      isUploadingScannedCodes: false
    };
  },
  [UPLOAD_SCANNED_CODES_FAILURE]: state => {
    return {
      ...state,
      isUploadingScannedCodes: false,
      error: "Upload Scanned Codes Failed"
    };
  }
});

export default accountsReducer;
