import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";

import { IS_DEV } from "../utils";

const firebaseProdConfig = {
  apiKey: "AIzaSyBJtQrBgxOvTDiJlKPt9QAgo1KjdxR_vWA",
  authDomain: "business-namaste.firebaseapp.com",
  databaseURL: "https://business-namaste-default-rtdb.firebaseio.com",
  projectId: "business-namaste",
  storageBucket: "business-namaste.appspot.com",
  messagingSenderId: "430884876971",
  appId: "1:430884876971:web:86c202f6714c2c445ffdee",
  measurementId: "G-1ZZJFTH20L"
};

const devFirebaseConfig = {
  apiKey: "AIzaSyAUW2SLqdkFjBzh0cVG-Gozi5MIGjsKb4s",
  authDomain: "namaste-dev.firebaseapp.com",
  databaseURL:
    "https://namaste-dev-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "namaste-dev",
  storageBucket: "namaste-dev.appspot.com",
  messagingSenderId: "1026364334219",
  appId: "1:1026364334219:web:e7a90bb4f6a27bf845b48e",
  measurementId: "G-EM9DJXQVHK"
};

const firebaseConfig = IS_DEV ? devFirebaseConfig : firebaseProdConfig;

export const app = initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const storage = getStorage(app);
export const auth = getAuth(app);
