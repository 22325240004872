import React, { Children, cloneElement, useEffect, useState } from "react";

export const menuItemComponent = handleMenuItemClick => {
  const MenuItemComponent = props => {
    const {
      children,
      onClick,
      currentPathname,
      route,
      active: propActive,
      iconName,
      label
    } = props;
    const hasChildren = !!Children.count(children);
    const [active, setActive] = useState(hasChildren ? null : null);
    useEffect(() => {
      if (hasChildren) {
        Children.map(children, (child, index) => {
          // Getting active menuItem from route. Using map as there is no some in Children
          if (
            currentPathname &&
            currentPathname.indexOf(child.props.route) > -1
          ) {
            setActive(index);
          }
        });
      }
    }, []);

    const handleClick = () => {
      if (onClick) {
        onClick();
      }

      // Only if a MenuItem does not have children, push that location.
      if (!hasChildren && route) {
        handleMenuItemClick(route);
      }
    };

    return (
      <li className={propActive ? "active" : ""}>
        <a
          onClick={handleClick}
          className="flex items-center cursor-pointer no-underline border-green-700 ">
          {iconName && (
            <span className="nav-icon no-fade">
              <i
                className="flex justify-center material-icons"
                style={{
                  fontSize: 18
                }}>
                {iconName}
              </i>
            </span>
          )}
          <span className="nav-text">{label}</span>
          {hasChildren && (
            <span className="flex items-center ml-auto nav-caret">
              <i className="material-icons">arrow_drop_down</i>
            </span>
          )}
        </a>
        {hasChildren ? (
          <ul className="nav-sub">
            {Children.map(children, (child, index) =>
              cloneElement(child, {
                onClick: () => setActive(active === index ? null : index), // If the current tab is already selected, then unselect it (to collapse subMenu), else select the tab
                active: active === index,
                pathname: currentPathname
              })
            )}
          </ul>
        ) : null}
      </li>
    );
  };

  return MenuItemComponent;
};
